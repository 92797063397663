import React, { useState } from "react";

function FileTables({ files }) {
  const [fileNames, setfileNames] = useState(null);
  const [ipName, setipName] = useState(null);

  const handleClickIp = (listIp, ip) => {
    setfileNames(listIp);
    setipName(ip);
  };
  return (
    <div className="table-responsive">
      <table className="table mb-0 table-borderless tbl-server-info">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col" />
            <th scope="col">Adresse IP</th>
            <th scope="col">Date</th>
            <th scope="col">Status</th>
            <th scope="col">Provider</th>
            <th scope="col" />
          </tr>
        </thead>

        <tbody>
          {files?.map((el, idx) => (
            <tr key={idx}>
              <td>
                <div className="avatar-40 text-center rounded-circle iq-bg-danger position-relative">
                  <span className="font-size-20 align-item-center">
                    <i className="fa fa-server" aria-hidden="true" />
                    <span className="bg-success dots" />
                  </span>
                </div>
              </td>
              <td>
                <h6>{el?.server_name}</h6>
                {/* <span className="text-body font-weight-400">
                8GB/80GB/SF02-Ubuntu Iconic- jfkakf-daksl...
              </span> */}
              </td>
              <td>{el?.server_name}</td>
              <td>
                {el?.server_date?.date.substring(0, 4) +
                  "-" +
                  el?.server_date?.date.substring(4, 6) +
                  "-" +
                  el?.server_date?.date.substring(6, 8)}
              </td>
              <td>
                <div data-toggle="tooltip" data-placement="top" title={el?.server_date.file} className="text-success">
                  effectué
                </div>
              </td>
              <td data-toggle="tooltip" data-placement="top" title={el?.server_date.file}>
                Vps ovh
              </td>
              <td>
                <span className="text-black font-size-24" id="dropdownMenuButton3">
                  <i
                    className="ri-eye-fill"
                    onClick={() => handleClickIp(el?.server_date.file, el?.server_name)}
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        className="modal fade"
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {ipName}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {fileNames?.map((element) => (
                <p>{element}</p>
              ))}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FileTables;
