export const filterWebsite = (arrayObject, string) => {
  const filtered = arrayObject.filter((el) => el.url.toLowerCase().includes(string.toLowerCase()));
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

export const filterApi = (arrayObject, string) => {
  const filtered = arrayObject.filter((el) => el.url.toLowerCase().includes(string.toLowerCase()));
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

export const filterIps = (arrayObject, string) => {
  const filtered = arrayObject.filter((el) => el.ip.toLowerCase().includes(string.toLowerCase()));
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};

export const filterStatus = (arrayObject, string) => {
  const filtered = arrayObject.filter((el) => el.status == string);
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};
export const filterEtat = (arrayObject, string) => {
  const filtered = arrayObject.filter((el) => el.etat == string);
  if (filtered.length === 0) {
    return arrayObject;
  } else {
    return filtered;
  }
};
