/**
 *
 * @param {*} min
 * @param {*} max
 * @returns
 */
export const randomIntFromInterval = (min, max) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};
