import React from "react";
import { Link } from "react-router-dom";

function ProcessorsTable({ data }) {
  return (
    <div className="col-lg-5">
      <div className="iq-card iq-card-block iq-card-stretch iq-card-height bg-danger">
        <div className="iq-card-body">
          <div className="d-flex justify-content-between align-items-center">
            <div className="icon avatar-70 bg-white text-danger iq-border-alert rounded-circle line-height-5 text-center">
              <i className="ri-alert-line" />
            </div>
            <div className="right-detail text-right">
              <p className="mb-0">Processeurs</p>
              <h3 className="text-white">
                {data.cpus_total_percent}
                <span className="font-size-12">%</span>
              </h3>
            </div>
          </div>
          <ul className="list-inline p-0 mt-5">
            {data.processors.slice(0, 3).map((el, idx) => (
              <li key={idx} className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="text-white">{el.name.split("@")[0]}</h5>
                  <div className="btn bg-white">{el.name.split("@")[1]}</div>
                </div>
                <p className="mb-0">Core {idx}</p>
              </li>
            ))}
          </ul>
          <Link to="#" className="d-block text-center mt-4 text-white font-weight-500">
            Voir plus
            <i className="ri-arrow-down-s-line" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ProcessorsTable;
