import React, { useEffect, useState } from "react";
import Pagination from "../Pagination/Pagination";
import FileTables from "../Tables/FileTables";
import axios from "axios";
import { getAllServers } from "../../Utils/GoupByUtil";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorAlert from "../Others/ErrorAlert";
const ENDPOINT = process.env.REACT_APP_API_BASE_URL;
function Backup() {
  const [oldDate, setoldDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split("T")[0].split("-").join("")
  );
  const [listServers, setlistServers] = useState(null);
  const [serverNoBack, setserverNoBack] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentData, setcurrentData] = useState([]);
  const [files, setFiles] = useState([]);
  const [size] = useState(7);

  useEffect(() => {
    setLoading(true);
    axios
      .get(ENDPOINT + "/file")
      .then((res) => {
        var string1 = JSON.stringify(res.data);
        var parsed = JSON.parse(string1);
        var allServerList = getAllServers(parsed);
        setlistServers(allServerList);
        var newFilter = parsed.filter((el) => {
          return el.server_date.date == oldDate;
        });
        var allServerListYesterday = getAllServers(newFilter);

        var array3 = allServerList.filter(function (obj) {
          return allServerListYesterday.indexOf(obj) == -1;
        });

        setserverNoBack(array3);
        setFiles(newFilter);
        console.log(newFilter);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };

  return (
    <>
      {loading && (
        <div className="container mt-5">
          <div className="d-flex align-items-center justify-content-center h-100">
            <CustomSyncLoader></CustomSyncLoader>
          </div>
        </div>
      )}
      {!loading && error && <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>}
      {!loading && !error && files && (
        <>
          <div className="col-lg-12">
            <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div className="iq-card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="icon avatar-70 bg-danger iq-border-danger rounded-circle line-height-5 text-center">
                    <i className="ri-alert-line" />
                  </div>
                  <div className="right-detail text-right">
                    <p className="mb-0">Backup échoué</p>
                    <h3>
                      {serverNoBack?.length}
                      <span className="font-size-12">Ips</span>
                    </h3>
                  </div>
                </div>
                <ul className="list-inline p-0 mt-4">
                  {serverNoBack?.map((el, idx) => (
                    <li key={idx} className="mb-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5>{el}</h5>
                        <div className="btn border-none alert-danger">échoué</div>
                      </div>
                      <p className="mb-0">
                        {oldDate.substring(0, 4) + "-" + oldDate.substring(4, 6) + "-" + oldDate.substring(6, 8)}
                      </p>
                    </li>
                  ))}
                </ul>
                {/* <a href="#" className="d-block text-center mt-4 text-black font-weight-500">
        View all
        <i className="ri-arrow-down-s-line" />
      </a> */}
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div className="iq-card-header d-flex justify-content-between">
                <div className="iq-header-title">
                  <h4 className="card-title">
                    Backup : {files.length} /{listServers?.length}
                  </h4>
                </div>
                {/* <div className="iq-card-header-toolbar d-flex align-items-center">
                  <div className="dropdown">
                    <span className="dropdown-toggle text-primary" id="dropdownMenuButton2" data-toggle="dropdown">
                      <i className="ri-more-2-fill" />
                    </span>
                    <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton2">
                      <a className="dropdown-item" href="#">
                        <i className="ri-eye-fill mr-2" />
                        View
                      </a>
                      <a className="dropdown-item" href="#">
                        <i className="ri-delete-bin-6-fill mr-2" />
                        Delete
                      </a>
                      <a className="dropdown-item" href="#">
                        <i className="ri-pencil-fill mr-2" />
                        Edit
                      </a>
                      <a className="dropdown-item" href="#">
                        <i className="ri-printer-fill mr-2" />
                        Print
                      </a>
                      <a className="dropdown-item" href="#">
                        <i className="ri-file-download-fill mr-2" />
                        Download
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="iq-card-body">
                <FileTables files={currentData}></FileTables>
              </div>
              <div className="row justify-content-between mt-3 m-2">
                <div id="user-list-page-info" className="col-md-6">
                  <span>
                    Affichage de 1 à {size} sur {files.length} entrées
                  </span>
                </div>
                <div className="col-md-6">
                  <Pagination items={files} onChangePage={onChangePage} pageSize={size} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Backup;
