import React from "react";

function NetworkTables({ data }) {
  return (
    <table className="table mb-0 mt-2 table-borderless table-test">
      <tbody>
        {data.network_cards.map((el, idx) => (
          <tr key={idx}>
            <td>
              <h6>{el.name}</h6>
              <p className="mb-0">{el.netmask}</p>
            </td>
            <td>
              <p className="mb-0">{el.address6}</p>
              <span className="text-primary">adresse ipv4</span>
            </td>
            <td className="text-right">
              <div className="text-success">{el.address}</div>
              <p className="mb-0">adresse ipv4</p>
            </td>
            {/* <td className="float-right">
              <div className="d-flex align-items-center text-right">
                <a href="#" className="avatar-45 text-center ml-3 rounded-circle iq-bg-danger font-size-18">
                  <i className="ri-pencil-fill" />
                </a>
                <a href="#" className="avatar-45 rounded-circle text-center ml-3 iq-bg-primary font-size-18">
                  <i className="ri-calendar-2-fill" />
                </a>
                <a href="#" className="avatar-45 rounded-circle text-center ml-3 iq-bg-success font-size-18">
                  <i className="ri-play-fill" />
                </a>
              </div>
            </td> */}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default NetworkTables;
