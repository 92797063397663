import React, { useEffect, useState } from "react";
import Pagination from "../Pagination/Pagination";
import axios from "axios";
import CustomSyncLoader from "../Others/CustomSyncLoader";
import ErrorAlert from "./../Others/ErrorAlert";
import DomaineTable from "../Tables/DomaineTable";
import { filterEtat, filterWebsite } from "../../Utils/SearchUtil";
import { Link } from "react-router-dom";
const ENDPOINT = process.env.REACT_APP_API_BASE_URL;

function Website() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [allInitialData, setallInitialData] = useState([]);
  const [currentData, setcurrentData] = useState([]);
  const [size] = useState(7);
  useEffect(() => {
    setLoading(true);
    axios
      .get(ENDPOINT + "/domaine")
      .then((res) => {
        var string1 = JSON.stringify(res.data);
        var parsed = JSON.parse(string1);
        setData(parsed);
        setallInitialData(parsed);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);
  const onChangePage = (pageOfItems) => {
    // update state with new page of items
    setcurrentData(pageOfItems);
  };
  const handleSearch = (value) => {
    const dataSearch = filterWebsite(allInitialData, value);
    setData(dataSearch);
  };
  const handleFilter = (value) => {
    const newFiltred = filterEtat(allInitialData, value);
    setData(newFiltred);
  };
  return (
    <>
      {loading && (
        <div className="container mt-5">
          <div className="d-flex align-items-center justify-content-center h-100">
            <CustomSyncLoader></CustomSyncLoader>
          </div>
        </div>
      )}
      {!loading && error && <ErrorAlert msg={`Erreur: ${error}`}></ErrorAlert>}
      {!loading && !error && data && (
        <div className="col-sm-12">
          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Sites clediss</h4>
              </div>
            </div>

            <div className="iq-card-body">
              <div className="table-responsive">
                <div className="row justify-content-between mb-2">
                  <div className="col-sm-12 col-md-6">
                    <div id="user_list_datatable_info" className="dataTables_filter">
                      <form className="mr-3 position-relative">
                        <div className="form-group mb-0">
                          <input
                            type="search"
                            className="form-control"
                            id="exampleInputSearch"
                            placeholder="Recherche ..."
                            aria-controls="user-list-table"
                            onChange={(e) => {
                              handleSearch(e.target.value);
                            }}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <div className="user-list-files d-flex float-right">
                      <Link className="iq-bg-success" to="#" onClick={() => handleFilter("active")}>
                        Active
                      </Link>
                      <Link className="iq-bg-danger" to="#" onClick={() => handleFilter("noactive")}>
                        Non active
                      </Link>
                    </div>
                  </div>
                </div>
                <DomaineTable domaines={currentData}></DomaineTable>
              </div>
            </div>
            <div className="row justify-content-between mt-3 m-2">
              <div id="user-list-page-info" className="col-md-6">
                <span>
                  Affichage de 1 à {size} sur {data.length} entrées
                </span>
              </div>
              <div className="col-md-6 mb-2">
                <Pagination items={data} onChangePage={onChangePage} pageSize={size} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Website;
