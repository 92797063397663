import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

function Login({ socket }) {
  const history = useHistory();
  const myRefOpen = useRef(null);
  const myRefHide = useRef(null);
  const [pass, setpass] = useState(null);
  const [code, setcode] = useState(null);
  const [received, setreceived] = useState(null);

  const handleLogin = () => {
    if (pass == process.env.REACT_APP_PASS_FIRST) {
      myRefOpen.current.click();
      //send socket to server
      socket.emit("code", "what is going on");
      socket.on("email", (data) => {
        setreceived(data.code);
      });
      //server send mail
      //receive socket from server
    } else {
      alert("mot de passe incorrect");
    }
  };

  const handleValidate = () => {
    if (code == process.env.REACT_APP_PASS_SECOND) {
      myRefHide.current.click();
      localStorage.setItem("loginServer", true);
      history.push("/");
    } else {
      alert("code incorrect");
    }
  };

  useEffect(() => {
    socket.on("connect", () => {
      //console.log("connected from login");
    });
  }, []);

  return (
    <section className="sign-in-page">
      <div className="container p-0" id="sign-in-page-box">
        <div className="bg-white form-container sign-in-container">
          <div className="sign-in-page-data">
            <div className="sign-in-from w-100 m-auto">
              <div className="sign-in-from">
                <img src="images/user/1.jpg" alt="user-image" className="rounded" />
                <h4 className="mt-3 mb-0">Hi ! CLediss</h4>
                <p className="text-dark">Entrez votre mot de passe pour accéder à l'interface administartion.</p>

                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Mot de passe</label>
                  <input
                    type="Password"
                    className="form-control mb-0"
                    id="exampleInputPass"
                    placeholder="Password"
                    onChange={(e) => setpass(e.target.value)}
                    value={pass || ""}
                  />
                </div>
                <div className="d-inline-block w-100">
                  <button className="btn btn-primary float-right" onClick={handleLogin}>
                    Ensuite
                  </button>

                  <button
                    ref={myRefOpen}
                    style={{ display: "none" }}
                    type="submit"
                    className="btn btn-primary float-right"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  >
                    ff
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-panel overlay-right">
              <a className="sign-in-logo mb-5" href="#">
                <img src="images/logo-full.png" className="img-fluid" alt="logo" />
              </a>
              <p>Entrez vos données personnelles et commencez à surveiller vos serveurs</p>
            </div>
          </div>
        </div>
      </div>
      {/* modal */}
      <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Code de connexion envoyé
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Vérifié votre votre email</label>
                <input
                  type="text"
                  className="form-control mb-0"
                  id="exampleInputCode"
                  placeholder="Code"
                  onChange={(e) => setcode(e.target.value)}
                  value={code || ""}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" ref={myRefHide}>
                Annuler
              </button>
              <button type="button" className="btn btn-primary" onClick={handleValidate}>
                Se connecter
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
