import React from "react";
import { randomIntFromInterval } from "../../Utils/FonctionUtil";

function ApiTable({ apis }) {
  return (
    <div className="table-responsive">
      <table className="table mb-0 table-borderless">
        <thead>
          <tr>
            <th scope="col">url</th>
            <th scope="col">Status</th>
            <th scope="col">Error</th>
            <th>
              <div className="d-flex justify-content-between">
                <span>Temps de réponse en (s)</span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {apis.map((el, idx) => (
            <tr key={idx}>
              <td>{el.url}</td>
              <td>
                {el.etat == "active" ? (
                  <span className="badge iq-bg-success">{el.etat}</span>
                ) : (
                  <span className="badge iq-bg-danger">{el.etat}</span>
                )}
              </td>
              <td>{el.error}</td>
              <td>
                <div className="progress m-2" style={{ height: 8 }}>
                  <div
                    className="progress-bar bg-primary rounded"
                    role="progressbar"
                    style={{ width: `${randomIntFromInterval(1, 99)}%`, height: 8 }}
                  />
                  <div
                    className="progress-bar bg-danger rounded-right position-relative"
                    role="progressbar"
                    style={{ width: `${randomIntFromInterval(1, 99)}%`, height: 8 }}
                  />
                  <div
                    className="progress-bar bg-success rounded-right position-relative"
                    role="progressbar"
                    style={{ width: `${randomIntFromInterval(1, 99)}%`, height: 8 }}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ApiTable;
