import React from "react";
import Dashboard from "./Layout/Dashboard";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Login from "./Layout/Login";
import PrivateRoute from "./Routes/PrivateRoute";
import PublicRoute from "./Routes/PublicRoute";
import socket from "./Connection/ws";

function App() {
  const DashboardWithSocket = () => <Dashboard socket={socket} />;
  const LoginWithSocket = () => <Login socket={socket} />;
  return (
    <Router>
      <Switch>
        {/* <Route path="/" component={Dashboard} exact />
        <Route path="/login" component={Login} /> */}
        <PrivateRoute component={DashboardWithSocket} path="/" exact></PrivateRoute>
        <PublicRoute component={LoginWithSocket} path="/login"></PublicRoute>
      </Switch>
    </Router>
  );
}

export default App;
