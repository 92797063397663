import React from "react";

function Error() {
  return (
    <div className="container p-0">
      <div className="row no-gutters height-self-center">
        <div className="col-sm-12 text-center align-self-center">
          <div className="iq-error position-relative">
            <img src="images/error/500.png" className="img-fluid iq-error-img" alt="" />
            <h1 className="text-in-box">500</h1>
            <h2 className="mb-0">Oops! This Page is Not Working.</h2>
            <p>The requested is Internal Server Error.</p>
            <a className="btn btn-primary mt-3" href="#">
              <i className="ri-home-4-line" />
              Rafraîchir
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Error;
