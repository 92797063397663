import React, {useState} from "react";
import ReactJsAlert from "reactjs-alert";

export default function ErrorAlert(props) {
  const [status, setstatus] = useState(true);
  return (
    <ReactJsAlert
      type="error" // success, warning, error, info
      title="Erreur!" // title you want to display
      status={true} // true or false
      color="#FA3434"
      quote={props.msg}
      Close={() => setstatus(false)}
      // callback method for hide
    />
  );
}
