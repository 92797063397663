import io from "socket.io-client";
import React, { useState, useEffect } from "react";
import axios from "axios";
import DiskTables from "../Components/Tables/DiskTables";
import Error from "../Components/Error/Error";
import Loading from "../Components/Loader/Loading";
import ProcessorsTable from "../Components/Tables/ProcessorsTable";
import NetworkTables from "../Components/Tables/NetworkTables";
import { Link, useHistory } from "react-router-dom";
import Backup from "../Components/Backup/Backup";
import Ips from "../Components/Ips/Ips";
import Website from "../Components/Website/Website";
import Apis from "../Components/Apis/Apis";

function Dashboard({ socket }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState("");
  const [disks, setdisks] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_API_BASE_URL)
      .then((res) => {
        setData(res.data);
        setdisks(res.data.disks);
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    // socket.on("connect", () => {
    //   console.log(socket.connected);
    // });
    socket.on("success", (data) => {
      //console.log(data)
      // console.log(JSON.parse(data.data));
      setData(JSON.parse(data.data));
    });

    const interval = setInterval(() => {
      //console.log("This will run every second!");
      socket.emit("infos", "what is going on");
    }, 9000);
    return () => clearInterval(interval);
  }, []);

  const handleLogout = () => {
    localStorage.setItem("loginServer", false);
    history.push("/login");
  };
  return (
    <>
      {loading && <Loading></Loading>}
      {!loading && error && <Error></Error>}
      {!loading && !error && data && (
        <div>
          <div className="wrapper">
            {/* TOP Nav Bar */}
            <div className="iq-top-navbar">
              <div className="iq-navbar-custom d-flex align-items-center justify-content-between">
                <div className="iq-menu-bt d-flex align-items-center">
                  <div className="wrapper-menu">
                    <div className="main-circle">
                      <i className="ri-menu-line" />
                    </div>
                    <div className="hover-circle">
                      <i className="ri-close-fill" />
                    </div>
                  </div>
                  <div className="iq-navbar-logo d-flex justify-content-between">
                    <Link to="#" className="header-logo">
                      <img src="images/logo.png" className="img-fluid rounded-normal" alt="" />
                      <div className="pt-2 pl-2 logo-title">
                        <span className="text-danger text-uppercase">
                          Serveur
                          <span className="text-primary ml-1">Clediss</span>
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>

                <nav className="navbar navbar-expand-lg navbar-light p-0">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-label="Toggle navigation"
                  >
                    <i className="ri-menu-3-line" />
                  </button>
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto navbar-list">
                      <li className="nav-item nav-icon">
                        <div className="iq-search-bar">
                          <form action="#" className="searchbox">
                            <input type="text" className="text search-input font-size-12" placeholder="Tapez ici pour rechercher..." />
                            <a className="search-link" href="#">
                              <i className="ri-search-line" />
                            </a>
                          </form>
                        </div>
                      </li>
                      <li className="nav-item nav-icon">
                        <a href="#" className="search-toggle iq-waves-effect text-white rounded">
                          <i className="ri-notification-line font-size-21 block" />
                          <span className="bg-danger dots" />
                        </a>
                        <div className="iq-sub-dropdown">
                          <div className="iq-card shadow-none m-0">
                            <div className="iq-card-body p-0 ">
                              <div className="bg-primary p-3">
                                <h5 className="mb-0 text-white">
                                  All Notifications
                                  <small className="badge  badge-light float-right pt-1">4</small>
                                </h5>
                              </div>
                              <a href="#" className="iq-sub-card">
                                <div className="media align-items-center">
                                  <div>
                                    <img className="avatar-40 rounded" src="images/user/01.jpg" alt="" />
                                  </div>
                                  <div className="media-body ml-3">
                                    <h6 className="mb-0 ">Emma Watson Barry</h6>
                                    <small className="float-right font-size-12">Just Now</small>
                                    <p className="mb-0">95 MB</p>
                                  </div>
                                </div>
                              </a>
                              <a href="#" className="iq-sub-card">
                                <div className="media align-items-center">
                                  <div>
                                    <img className="avatar-40 rounded" src="images/user/02.jpg" alt="" />
                                  </div>
                                  <div className="media-body ml-3">
                                    <h6 className="mb-0 ">New customer is join</h6>
                                    <small className="float-right font-size-12">5 days ago</small>
                                    <p className="mb-0">Cyst Barry</p>
                                  </div>
                                </div>
                              </a>
                              <a href="#" className="iq-sub-card">
                                <div className="media align-items-center">
                                  <div>
                                    <img className="avatar-40 rounded" src="images/user/03.jpg" alt="" />
                                  </div>
                                  <div className="media-body ml-3">
                                    <h6 className="mb-0 ">Two customer is left</h6>
                                    <small className="float-right font-size-12">2 days ago</small>
                                    <p className="mb-0">Cyst Barry</p>
                                  </div>
                                </div>
                              </a>
                              <a href="#" className="iq-sub-card">
                                <div className="media align-items-center">
                                  <div>
                                    <img className="avatar-40 rounded" src="images/user/04.jpg" alt="" />
                                  </div>
                                  <div className="media-body ml-3">
                                    <h6 className="mb-0 ">New Mail from Fenny</h6>
                                    <small className="float-right font-size-12">3 days ago</small>
                                    <p className="mb-0">Cyst Barry</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <ul className="navbar-list">
                    <li className="line-height">
                      <a href="#" className="search-toggle iq-waves-effect d-flex align-items-center">
                        <img src="images/user/user.png" className="img-fluid rounded-circle mr-3" alt="user" />
                      </a>
                      <div className="iq-sub-dropdown iq-user-dropdown">
                        <div className="iq-card shadow-none m-0">
                          <div className="iq-card-body p-0 ">
                            <div className="bg-primary p-3">
                              <h5 className="mb-0 text-white line-height">Bonjour Clediss</h5>
                              <span className="text-white font-size-12">en ligne</span>
                            </div>
                            {/* <a href="profile.html" className="iq-sub-card iq-bg-primary-hover">
                              <div className="media align-items-center">
                                <div className="rounded iq-card-icon iq-bg-primary">
                                  <i className="ri-file-user-line" />
                                </div>
                                <div className="media-body ml-3">
                                  <h6 className="mb-0 ">My Profile</h6>
                                  <p className="mb-0 font-size-12">View personal profile details.</p>
                                </div>
                              </div>
                            </a> */}
                            {/* <a href="profile-edit.html" className="iq-sub-card iq-bg-primary-hover">
                              <div className="media align-items-center">
                                <div className="rounded iq-card-icon iq-bg-primary">
                                  <i className="ri-profile-line" />
                                </div>
                                <div className="media-body ml-3">
                                  <h6 className="mb-0 ">Edit Profile</h6>
                                  <p className="mb-0 font-size-12">Modify your personal details.</p>
                                </div>
                              </div>
                            </a> */}
                            {/* <a href="account-setting.html" className="iq-sub-card iq-bg-primary-hover">
                              <div className="media align-items-center">
                                <div className="rounded iq-card-icon iq-bg-primary">
                                  <i className="ri-account-box-line" />
                                </div>
                                <div className="media-body ml-3">
                                  <h6 className="mb-0 ">Account settings</h6>
                                  <p className="mb-0 font-size-12">Manage your account parameters.</p>
                                </div>
                              </div>
                            </a> */}
                            <a href="privacy-setting.html" className="iq-sub-card iq-bg-primary-hover">
                              <div className="media align-items-center">
                                <div className="rounded iq-card-icon iq-bg-primary">
                                  <i className="ri-lock-line" />
                                </div>
                                <div className="media-body ml-3">
                                  <h6 className="mb-0 ">Privacy Settings</h6>
                                  <p className="mb-0 font-size-12">Control your privacy parameters.</p>
                                </div>
                              </div>
                            </a>
                            <div className="d-inline-block w-100 text-center p-3">
                              <a className="bg-primary iq-sign-btn" href="#" role="button" onClick={handleLogout}>
                                Sign out
                                <i className="ri-login-box-line ml-2" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            {/* TOP Nav Bar END */}
            {/* Page Content  */}

            <div id="content-page" className="content-page">
              <div className="container-fluid">
                <div className="row content-body">
                  <div className="col-lg-8">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height iq-bg-danger">
                      <div className="iq-card-body box iq-box-relative">
                        <div className="box-image float-right">
                          <img className="rounded img-fluid" src="images/page-img/37.png" alt="profile" />
                        </div>
                        <h4 className="d-block mb-3 text-black">{data.hostname}</h4>
                        <p className="d-inline-block welcome-text text-black">{data.platform_i}</p>
                        <p className="d-inline-block welcome-text text-black">{data.version_i}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-body">
                        <ul className="suggestions-lists m-0 p-0">
                          <li className="d-flex mb-4 align-items-center justify-content-between">
                            <div className="col-sm-9 p-0">
                              <div className="d-flex align-items-center">
                                <div className="avatar-55 text-center rounded iq-bg-danger">
                                  <span>{data.cpu_usage_percent}</span>
                                </div>
                                <div className="media-support-info ml-3">
                                  <h5>CPU</h5>
                                  <p className="mb-0">Avec interval de 1</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-3 p-0">
                              <div className="iq-progress-bar-linear d-inline-block mt-1 w-100">
                                <div className="progress" style={{ height: "6px" }}>
                                  <div className="progress-bar bg-danger" role="progressbar" style={{ width: `${data?.cpu_usage_percent * 10}%` }} />
                                </div>
                              </div>
                            </div>
                          </li>
                          <li className="d-flex align-items-center justify-content-between">
                            <div className="col-sm-9 p-0">
                              <div className="d-flex align-items-center">
                                <div className="avatar-55 text-center rounded iq-bg-primary">
                                  <span>{data.cpus_total_percent}</span>
                                </div>
                                <div className="media-support-info ml-3">
                                  <h5>CPU</h5>
                                  <p className="mb-0">Sans interval</p>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-3 p-0">
                              <div className="iq-progress-bar-linear d-inline-block mt-1 w-100">
                                <div className="progress" style={{ height: "6px" }}>
                                  <div
                                    className="progress-bar bg-primary"
                                    role="progressbar"
                                    style={{ width: `${data?.cpus_total_percent * 10}%` }}
                                  />
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 row m-0 p-0">
                    <div className="col-sm-6 col-md-6 col-lg-3">
                      <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                        <div className="iq-card-body">
                          <div className="icon iq-icon-box iq-bg-primary rounded" data-wow-delay="0.2s">
                            <i className="ri-cpu-line" />
                          </div>
                          <div className="mt-4">
                            <h5 className="text-black text-uppercase">CPU</h5>
                            <h3 className="d-flex text-primary">
                              {data.cpus_total_percent}%
                              <i className="ri-arrow-up-line" />
                            </h3>
                          </div>
                          {/* <p className="mb-0 mt-1">Avg +65%</p> */}
                          <div className="mt-3">
                            <div className="iq-progress-bar-linear d-inline-block mt-1 w-100">
                              <div className="progress" style={{ height: "6px" }}>
                                <div className="progress-bar bg-primary" role="progressbar" style={{ width: `${data?.cpus_total_percent * 10}%` }} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                      <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                        <div className="iq-card-body">
                          <div className="icon iq-icon-box iq-bg-danger rounded" data-wow-delay="0.2s">
                            <i className="ri-window-line" />
                          </div>
                          <div className="mt-4">
                            <h5 className="text-black text-uppercase">RAM</h5>
                            <h3 className="d-flex text-danger">
                              {" "}
                              {data.m_percent}%
                              <i className="ri-arrow-down-line" />
                            </h3>
                          </div>
                          {/* <p className="mb-0 mt-1">Avg +85%</p> */}
                          <div className="mt-3">
                            <div className="iq-progress-bar-linear d-inline-block mt-1 w-100">
                              <div className="progress" style={{ height: "6px" }}>
                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: `${data?.m_percent * 10}%` }} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                      <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                        <div className="iq-card-body">
                          <div className="icon iq-icon-box iq-bg-primary rounded" data-wow-delay="0.2s">
                            <i className="ri-u-disk-line" />
                          </div>
                          <div className="mt-4">
                            <h5 className="text-black text-uppercase">DISK</h5>
                            <h3 className="d-flex text-primary">
                              {" "}
                              {data.total_write_disk}
                              <i className="ri-arrow-up-line" />
                            </h3>
                          </div>
                          {/* <p className="mb-0 mt-1">Avg +36%</p> */}
                          <div className="mt-3">
                            <div className="iq-progress-bar-linear d-inline-block mt-1 w-100">
                              <div className="progress" style={{ height: "6px" }}>
                                <div
                                  className="progress-bar bg-primary"
                                  role="progressbar"
                                  style={{ width: `${data?.total_write_disk.split(" ")[0]}%` }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-3">
                      <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                        <div className="iq-card-body">
                          <div className="icon iq-icon-box iq-bg-danger rounded" data-wow-delay="0.2s">
                            <i className="ri-global-line" />
                          </div>
                          <div className="mt-4">
                            <h5 className="text-black text-uppercase">SERVICES</h5>
                            <h3 className="d-flex text-danger">
                              {" "}
                              {data.total_bytes_received}
                              <i className="ri-arrow-down-line" />
                            </h3>
                          </div>
                          {/* <p className="mb-0 mt-1">Avg +48%</p> */}
                          <div className="mt-3">
                            <div className="iq-progress-bar-linear d-inline-block mt-1 w-100">
                              <div className="progress" style={{ height: "6px" }}>
                                <div
                                  className="progress-bar bg-danger"
                                  role="progressbar"
                                  style={{ width: `${data?.total_bytes_received.split(" ")[0]}%` }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="iq-card bg-danger iq-card-block iq-card-stretch iq-card-height-half">
                      <div className="iq-card-body box iq-box-relative">
                        <div className="d-flex flex-wrap justify-content-between align-items-center">
                          <div className="col-7 p-0">
                            <div className="float-left progress-round income-progress mr-3" data-value="20">
                              <span className="progress-left">
                                <span className="progress-bar border-white" style={{ transform: "rotate(108deg)" }} />
                              </span>
                              <span className="progress-right">
                                <span className="progress-bar border-white" style={{ transform: "rotate(180deg)" }} />
                              </span>
                              <div className="progress-value w-100 h-100 rounded d-flex align-items-center justify-content-center text-center">
                                <div className="h6 mb-0">{data.memory_used_percent}</div>
                              </div>
                            </div>
                            <h5 className="d-block mt-2 text-white font-weight-500">
                              Mémoire
                              <br /> utilisé
                            </h5>
                          </div>
                          <div className="col-5 pr-0 right-border-block position-relative">
                            {/* <div class="avatar-30 text-center rounded-circle iq-bg-danger" data-wow-delay="0.2s">
                        <span class="font-size-14 align-item-center"><i class="ri-account-box-line"></i></span>
                     </div> */}
                            <h5 className="text-white mt-2">{data.uptime}</h5>
                            <span className="text-white">uptime</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height-half iq-background-image">
                      <div className="iq-card-body box iq-box-relative rounded">
                        <div className="d-flex justify-content-between align-items-left">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="float-left progress-round income-progress" data-value={80}>
                              <span className="progress-left">
                                <span className="progress-bar border-primary" style={{ transform: "rotate(108deg)" }} />
                              </span>
                              <span className="progress-right">
                                <span className="progress-bar border-success" style={{ transform: "rotate(180deg)" }} />
                              </span>
                            </div>
                            <div className="pl-3">
                              <ul className="float-right d-inline-block p-0 m-0 list-inline">
                                <li className="line-height-3">
                                  <span className="text-danger total-progress position-relative pl-2">
                                    <span className="bg-danger rounded" />
                                    Mémoire Total: {data.m_total}
                                    <i className="ri-arrow-up-line" />
                                  </span>
                                </li>
                                <li className="line-height-3">
                                  <span className="text-primary total-progress position-relative pl-2">
                                    <span className="bg-primary rounded" />
                                    Mémoire vide: {data.m_availabel}
                                    <i className="ri-arrow-down-line" />
                                  </span>
                                </li>
                                <li className="line-height-3">
                                  <span className="text-success total-progress position-relative pl-2">
                                    <span className="bg-success rounded" />
                                    Mémoire utilisé: {data.m_used}
                                    <i className="ri-arrow-up-line" />
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-body iq-box-relative">
                        <div className="iq-service d-flex align-items-center justify-content-between">
                          <div className="service-data">
                            <h2>{data.cpu_min_frequency_mhz}</h2>
                            <p className="mb-0">Fréquence CPU min</p>
                          </div>
                          <div id="service-chart-01" className="service-chart" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-body iq-box-relative">
                        <div className="iq-service d-flex align-items-center justify-content-between">
                          <div className="service-data">
                            <h2>{data.cpu_max_frequency_mhz}</h2>
                            <p className="mb-0">Fréquence CPU max</p>
                          </div>
                          <div id="service-chart-02" className="service-chart" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height bg-danger">
                      <div className="iq-card-body iq-box-relative">
                        <div className="iq-service d-flex align-items-center justify-content-between">
                          <div className="service-data">
                            <h2 className="text-white">{data.cpu_current_frequency_mhz}</h2>
                            <p className="mb-0">Fréquece CPU current</p>
                          </div>
                          <div id="service-chart-03" className="service-chart" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-body iq-box-relative">
                        <div className="iq-service d-flex align-items-center justify-content-between">
                          <div className="service-data">
                            <h2>{data.cpu_usage_percent} %</h2>
                            <p className="mb-0">Utilisation CPU</p>
                          </div>
                          <div id="service-chart-04" className="service-chart" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-2 col-lg-2">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-body">
                        <div className="icon iq-icon-box iq-bg-primary rounded">
                          <i className="ri-drag-move-2-fill" />
                        </div>
                        <div className="mt-4">
                          <h2>{data.swap_free}</h2>
                          <p>Mémoire swap vide</p>
                        </div>
                        <div id="ethernet-chart-01" />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-2 col-lg-2">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-body">
                        <div className="icon iq-icon-box iq-bg-success rounded" data-wow-delay="0.2s">
                          <i className="ri-artboard-2-line" />
                        </div>
                        <div className="mt-4">
                          <h2>{data.swap_percent} %</h2>
                          <p>Mémoire swap</p>
                        </div>
                        <div id="ethernet-chart-02" />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-2 col-lg-2">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-body">
                        <div className="icon iq-icon-box iq-bg-danger rounded" data-wow-delay="0.2s">
                          <i className="ri-map-pin-time-line" />
                        </div>
                        <div className="mt-4">
                          <h2>{data.swap_total}</h2>
                          <p>Mémoire swapt total</p>
                        </div>
                        <div id="ethernet-chart-03" />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-2 col-lg-2">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-body">
                        <div className="icon iq-icon-box bg-primary rounded" data-wow-delay="0.2s">
                          <i className="ri-timer-line" />
                        </div>
                        <div className="mt-4">
                          <h2>{data.swap_used}</h2>
                          <p>Mémoire swap utilisé</p>
                        </div>
                        <div id="ethernet-chart-04" />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height bg-primary rounded background-image-overlap">
                      <div className="iq-card-body">
                        <div className="d-flex align-items-center mb-3">
                          <div>
                            <img className="rounded" src="images/page-img/38.png" alt="" />
                          </div>
                          <h5 className="pl-3 text-white">{data.platform_i}</h5>
                        </div>
                        <p className="mb-2">
                          <span className="text-white">Architecture</span> {data.architecture}
                        </p>
                        <p className="mb-2">
                          <span className="text-white">Release</span> {data.release_i}
                        </p>
                        <p className="mb-3">
                          <span className="text-white">System</span> {data.system_i}
                        </p>
                        <button type="submit" className="btn w-100 btn-white mt-4 text-primary viwe-more">
                          Voir Plus
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Disks</h4>
                        </div>
                        {/* <div className="iq-card-header-toolbar d-flex align-items-center">
                          <div className="dropdown">
                            <span className="btn bg-danger">Add Monitor</span>
                          </div>
                        </div> */}
                      </div>
                      <div className="iq-card-body">
                        <DiskTables disks={disks}></DiskTables>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height  iq-bg-danger">
                      <div className="iq-card-body box iq-box-relative rounded float-left w-100">
                        <div className="box-image1 float-left">
                          <img className="rounded img-fluid" src="images/page-img/37.png" alt="profile" />
                        </div>
                        <div className="welcome-right">
                          <h4 className="d-block mb-3 text-black">Surveillance serveur backup</h4>
                          <p className="d-inline-block text-black">
                            il est intéressant de savoir quand un sauvgarde a été ajouté au serveur du backup.
                          </p>
                          <div className="d-block">
                            <Link to="#" className="btn btn-danger d-inline-block mb-0">
                              Voir plus
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-body ethernet-content">
                        <div className="d-flex align-items-center">
                          <h1 className="mr-5">{0}</h1>
                          <div className="float-right">
                            <h5 className="pt-3">Serveur</h5>
                            <p>ovh VPS</p>
                          </div>
                        </div>
                        <div id="ethernet-chart" />
                        {/* <div className="ethernet-text text-danger font-weight-600">+18%</div> */}
                      </div>
                    </div>
                  </div>
                  <Backup></Backup>
                  <ProcessorsTable data={data}></ProcessorsTable>
                  <div className="col-lg-7">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">cartes réseau</h4>
                        </div>
                        {/* <div className="iq-card-header-toolbar d-flex align-items-center">
                          <div className="dropdown">
                            <span
                              className="dropdown-toggle dropdown-bg iq-bg-danger"
                              id="dropdownMenuButton2"
                              data-toggle="dropdown"
                            >
                              Order by Creation
                              <i className="ri-arrow-down-s-line ml-1 text-danger" />
                            </span>
                            <div
                              className="dropdown-menu dropdown-menu-right shadow-none"
                              aria-labelledby="dropdownMenuButton2"
                            >
                              <a className="dropdown-item" href="#">
                                <i className="ri-eye-fill mr-2" />
                                View
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="ri-delete-bin-6-fill mr-2" />
                                Delete
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="ri-pencil-fill mr-2" />
                                Edit
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="ri-printer-fill mr-2" />
                                Print
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="ri-file-download-fill mr-2" />
                                Download
                              </a>
                            </div>
                            <span
                              className="dropdown-toggle dropdown-bg iq-bg-primary ml-4"
                              id="dropdownMenuButton3"
                              data-toggle="dropdown"
                            >
                              Sort by Name
                              <i className="ri-arrow-down-s-line ml-1 text-primary" />
                            </span>
                            <div
                              className="dropdown-menu dropdown-menu-right shadow-none"
                              aria-labelledby="dropdownMenuButton3"
                            >
                              <a className="dropdown-item" href="#">
                                <i className="ri-eye-fill mr-2" />
                                View
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="ri-delete-bin-6-fill mr-2" />
                                Delete
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="ri-pencil-fill mr-2" />
                                Edit
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="ri-printer-fill mr-2" />
                                Print
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="ri-file-download-fill mr-2" />
                                Download
                              </a>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="iq-card-body">
                        <div className="table-responsive">
                          <NetworkTables data={data}></NetworkTables>
                          <Link to="#" className="d-block text-center text-black font-weight-500">
                            Voir plus
                            <i className="ri-arrow-down-s-line" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Ips></Ips>
                  <Website></Website>
                  <Apis></Apis>
                  {/* <div className="col-lg-3">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height-third">
                      <div className="iq-card-body">
                        <div className="row">
                          <div className="col-lg-12 text-center position-relative">
                            <div className="icon avatar-70 m-auto bg-success iq-border-success rounded-circle line-height-7 text-center">
                              <i className="ri-check-line" />
                            </div>
                          </div>
                          <div className="col-lg-12 mt-3 text-center">
                            <h5>Homepage</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height-third">
                      <div className="iq-card-body">
                        <div className="row">
                          <div className="col-lg-12 text-center position-relative">
                            <div className="icon avatar-70 m-auto bg-success iq-border-success rounded-circle line-height-7 text-center">
                              <i className="ri-check-line" />
                            </div>
                          </div>
                          <div className="col-lg-12 mt-3 text-center">
                            <h5>Stock Apl</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height-third">
                      <div className="iq-card-body">
                        <div className="row">
                          <div className="col-lg-12 text-center position-relative">
                            <div className="icon avatar-70 m-auto bg-close iq-border-close rounded-circle line-height-7 text-center">
                              <i className="ri-close-line" />
                            </div>
                          </div>
                          <div className="col-lg-12 mt-3 text-center">
                            <h5>Order Process</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 row m-0 p-0 iq-duration-block">
                    <div className="col-lg-8">
                      <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title">Server Traffic Source</h4>
                          </div>
                          <div className="iq-card-header-toolbar d-flex align-items-center">
                            <div className="dropdown">
                              <span
                                className="dropdown-toggle dropdown-bg iq-bg-danger"
                                id="dropdownMenuButton1"
                                data-toggle="dropdown"
                              >
                                This Year
                                <i className="ri-arrow-down-s-line ml-1 text-danger" />
                              </span>
                              <div
                                className="dropdown-menu dropdown-menu-right shadow-none"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <a className="dropdown-item" href="#">
                                  <i className="ri-eye-fill mr-2" />
                                  View
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="ri-delete-bin-6-fill mr-2" />
                                  Delete
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="ri-pencil-fill mr-2" />
                                  Edit
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="ri-printer-fill mr-2" />
                                  Print
                                </a>
                                <a className="dropdown-item" href="#">
                                  <i className="ri-file-download-fill mr-2" />
                                  Download
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="iq-card-body">
                          <div id="report-chart-02" style={{ minHeight: 150 }} />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4">
                      <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                        <div className="iq-card-body">
                          <h4 className="text-uppercase text-black mb-0">Session(Now)</h4>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="font-size-80 text-black">12</div>
                            <div className="text-left">
                              <p className="m-0 text-uppercase font-size-12">1 Hours Ago</p>
                              <div className="mb-1 text-black">
                                1500
                                <span className="text-danger">
                                  <i className="ri-arrow-down-s-fill" />
                                  3.25%
                                </span>
                              </div>
                              <p className="m-0 text-uppercase font-size-12">1 Day Ago</p>
                              <div className="mb-1 text-black">
                                1890
                                <span className="text-success">
                                  <i className="ri-arrow-down-s-fill" />
                                  1.00%
                                </span>
                              </div>
                              <p className="m-0 text-uppercase font-size-12">1 Week Ago</p>
                              <div className="text-black">
                                1260
                                <span className="text-danger">
                                  <i className="ri-arrow-down-s-fill" />
                                  9.87%
                                </span>
                              </div>
                            </div>
                          </div>
                          <div id="wave-chart-7" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Bandwidth Public</h4>
                        </div>
                        <div className="mt-1">
                          <div className="d-flex align-items-center justify-content-between">
                            <a href="#" className="d-flex align-items-center mr-4">
                              <span className="bg-danger p-1 rounded mr-2" />
                              <p className="text-danger mb-0">Lowest Speed </p>
                            </a>
                            <a href="#" className="d-flex align-items-center">
                              <span className="bg-primary p-1 rounded mr-2" />
                              <p className="text-primary mb-0">Highest Speed</p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="iq-card-body">
                        <div id="iq-income-chart" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="iq-card">
                      <div className="iq-card-body box iq-box-relative rounded">
                        <div className="d-flex align-items-center">
                          <div className="float-left progress-round income-progress disk-usage" data-value={80}>
                            <span className="progress-left">
                              <span className="progress-bar border-success" style={{ transform: "rotate(108deg)" }} />
                            </span>
                            <span className="progress-right">
                              <span className="progress-bar border-primary" style={{ transform: "rotate(180deg)" }} />
                            </span>
                          </div>
                          <div className="pl-3">
                            <h5 className="d-block mt-0 mb-1 text-black">Disk Usage</h5>
                            <ul className="float-right d-inline-block p-0 m-0 list-inline">
                              <li className="line-height-3">
                                <span className="text-danger total-progress position-relative pl-2 font-size-12 font-weight-500">
                                  <span className="bg-danger rounded" />
                                  Utilisé : {data.disks[0].used_size}
                                </span>
                              </li>
                              <li className="line-height-3">
                                <span className="text-primary total-progress position-relative pl-2 font-size-12 font-weight-500">
                                  <span className="bg-primary rounded" />
                                  Total : {data.disks[0].total_size}
                                </span>
                              </li>
                              <li className="line-height-3">
                                <span className="text-success total-progress position-relative pl-2 font-size-12 font-weight-500">
                                  <span className="bg-success rounded" />
                                  Vide : {data.disks[0].total_free}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="iq-card">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Load Average</h4>
                        </div>
                      </div>
                      <div className="iq-card-body p-0">
                        <div id="chart-9" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 row m-0 p-0">
                    <div className="col-sm-6">
                      <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                        <div className="iq-card-header d-flex justify-content-between">
                          <div className="iq-header-title">
                            <h4 className="card-title">CPU Daily Usage</h4>
                          </div>
                        </div>
                        <div className="iq-card-body pt-0">
                          <div id="home-chart-11" />
                          <div className="text-center">
                            <h4>50.03%</h4>
                            <p className="mb-0">
                              CPU usage is
                              <span className="text-primary pl-2">good</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="iq-card bg-danger">
                        <div className="iq-card-body box iq-box-relative">
                          <div className="d-flex flex-wrap justify-content-between align-items-center">
                            <div className="col-4 p-0">
                              <div className="float-left progress-round income-progress mr-2" data-value={80}>
                                <span className="progress-left">
                                  <span className="progress-bar border-white" style={{ transform: "rotate(108deg)" }} />
                                </span>
                                <span className="progress-right">
                                  <span className="progress-bar border-white" style={{ transform: "rotate(180deg)" }} />
                                </span>
                                <div className="progress-value w-100 h-100 rounded d-flex align-items-center justify-content-center text-center">
                                  <div className="h4 mb-0">12</div>
                                </div>
                              </div>
                            </div>
                            <div className="col-8 pr-0">
                              <h5 className="d-block mt-2 mb-3 text-white">Most Recent Alarams</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="iq-card">
                        <div className="iq-card-body box iq-box-relative rounded">
                          <div className="d-flex align-items-center">
                            <div className="float-left progress-round heatmap-progress" data-value={80}>
                              <span className="progress-left">
                                <span className="progress-bar border-primary" style={{ transform: "rotate(108deg)" }} />
                              </span>
                              <span className="progress-right">
                                <span className="progress-bar border-success" style={{ transform: "rotate(180deg)" }} />
                              </span>
                            </div>
                            <div className="pl-3">
                              <h5 className="d-block mt-0 mb-1 text-black">Heat Map</h5>
                              <ul className="d-inline-block p-0 m-0 list-inline">
                                <li className="line-height-3">
                                  <span className="text-danger total-progress position-relative pl-2 font-size-12 font-weight-500">
                                    <span className="bg-danger rounded" />
                                    Clear
                                  </span>
                                </li>
                                <li className="line-height-3">
                                  <span className="text-primary total-progress position-relative pl-2 font-size-12 font-weight-500">
                                    <span className="bg-primary rounded" />
                                    Critical
                                  </span>
                                </li>
                                <li className="line-height-3">
                                  <span className="text-success total-progress position-relative pl-2 font-size-12 font-weight-500">
                                    <span className="bg-success rounded" />
                                    Trouble
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Ram Usage</h4>
                        </div>
                      </div>
                      <div className="iq-card-body">
                        <div id="report-chart-04" />
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              {/* <div className="container-fluid">
                <div className="row content-body">
                  <div className="col-lg-7">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Response Graph</h4>
                        </div>
                        <div className="mt-1">
                          <div className="d-flex align-items-center justify-content-between">
                            <a href="#" className="d-flex align-items-center mr-4">
                              <span className="bg-danger p-1 rounded mr-2" />
                              <p className="text-danger mb-0">Response Time</p>
                            </a>
                            <div className="iq-card-header-toolbar d-flex align-items-center">
                              <div className="dropdown">
                                <span
                                  className="dropdown-toggle dropdown-bg iq-bg-primary"
                                  id="dropdownMenuButton1"
                                  data-toggle="dropdown"
                                >
                                  7 Days
                                  <i className="ri-arrow-down-s-line ml-1 text-primary" />
                                </span>
                                <div
                                  className="dropdown-menu dropdown-menu-right shadow-none"
                                  aria-labelledby="dropdownMenuButton1"
                                >
                                  <a className="dropdown-item" href="#">
                                    <i className="ri-eye-fill mr-2" />
                                    View
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    <i className="ri-delete-bin-6-fill mr-2" />
                                    Delete
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    <i className="ri-pencil-fill mr-2" />
                                    Edit
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    <i className="ri-printer-fill mr-2" />
                                    Print
                                  </a>
                                  <a className="dropdown-item" href="#">
                                    <i className="ri-file-download-fill mr-2" />
                                    Download
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="iq-card-body">
                        <div id="activies-chart-01" />
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Activites</h4>
                        </div>
                        <div className="mt-1">
                          <div className="d-flex align-items-center justify-content-between">
                            <label className="mb-0 mr-2">Data Type: </label>
                            <div className="custom-control custom-checkbox custom-checkbox-color-check custom-control-inline">
                              <input
                                type="checkbox"
                                className="custom-control-input bg-primary"
                                id="customCheck-1"
                                defaultChecked
                              />
                              <label className="custom-control-label" htmlFor="customCheck-1">
                                {" "}
                                Operations
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox custom-checkbox-color-check custom-control-inline">
                              <input
                                type="checkbox"
                                className="custom-control-input bg-success"
                                id="customCheck-2"
                                defaultChecked
                              />
                              <label className="custom-control-label" htmlFor="customCheck-2">
                                API Calls
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox custom-checkbox-color-check custom-control-inline">
                              <input
                                type="checkbox"
                                className="custom-control-input bg-danger"
                                id="customCheck-3"
                                defaultChecked
                              />
                              <label className="custom-control-label" htmlFor="customCheck-3">
                                Errors
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="iq-card-body">
                        <div id="activies-chart" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Activities Percentage</h4>
                        </div>
                        <div className="iq-card-header-toolbar d-flex align-items-center">
                          <div className="dropdown">
                            <span
                              className="dropdown-toggle dropdown-bg iq-bg-dark"
                              id="dropdownMenuButton4"
                              data-toggle="dropdown"
                            >
                              Week
                              <i className="ri-arrow-down-s-line ml-1 text-dark" />
                            </span>
                            <div
                              className="dropdown-menu dropdown-menu-right shadow-none"
                              aria-labelledby="dropdownMenuButton4"
                            >
                              <a className="dropdown-item" href="#">
                                <i className="ri-eye-fill mr-2" />
                                View
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="ri-delete-bin-6-fill mr-2" />
                                Delete
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="ri-pencil-fill mr-2" />
                                Edit
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="ri-printer-fill mr-2" />
                                Print
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="ri-file-download-fill mr-2" />
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="iq-card-body">
                        <div className="activities-content position-relative mt-5">
                          <div className="avatar-235 activity activity-1 rounded-circle bg-danger text-center">
                            <h2 className="text-white line-height-4">51%</h2>
                            <p className="mb-0 line-height-3">Increase</p>
                          </div>
                          <div className="avatar-155 m-auto activity activity-2 rounded-circle bg-primary text-center">
                            <h3 className="text-white line-height-4">31%</h3>
                            <p className="mb-0 line-height-3">Increase</p>
                          </div>
                          <div className="avatar-110 m-auto activity activity-3 rounded-circle bg-success text-center">
                            <h3 className="text-white line-height-4">8%</h3>
                            <p className="mb-0 line-height-3">Decrease</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mb-1 mt-5">
                          <div className="text-center">
                            <h3>251</h3>
                            <p className="mb-0">Operations</p>
                          </div>
                          <div className="text-center">
                            <h3>198</h3>
                            <p className="mb-0">API Calls</p>
                          </div>
                          <div className="text-center">
                            <h3>21</h3>
                            <p className="mb-0">Errors</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid">
                <div className="row content-body">
                  <div className="col-lg-8">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Load Time - Last 24 hours</h4>
                        </div>
                        <div className="mt-1">
                          <div className="d-flex align-items-center justify-content-between">
                            <a href="#" className="d-flex align-items-center mr-4">
                              <span className="bg-primary p-1 rounded mr-2" />
                              <p className="text-primary mb-0">Lowest Speed </p>
                            </a>
                            <a href="#" className="d-flex align-items-center">
                              <span className="bg-danger p-1 rounded mr-2" />
                              <p className="text-danger mb-0">Highest Speed</p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="iq-card-body">
                        <div id="chart-001" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Active Instances</h4>
                        </div>
                        <div className="iq-card-header-toolbar d-flex align-items-center">
                          <div className="dropdown">
                            <span
                              className="dropdown-toggle text-primary"
                              id="dropdownMenuButton1"
                              data-toggle="dropdown"
                            >
                              <i className="ri-more-2-fill" />
                            </span>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton1">
                              <a className="dropdown-item" href="#">
                                <i className="ri-eye-fill mr-2" />
                                View
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="ri-delete-bin-6-fill mr-2" />
                                Delete
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="ri-pencil-fill mr-2" />
                                Edit
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="ri-printer-fill mr-2" />
                                Print
                              </a>
                              <a className="dropdown-item" href="#">
                                <i className="ri-file-download-fill mr-2" />
                                Download
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="iq-card-body">
                        <div id="httperror-chart" />
                        <div className="row mt-3 error-detail">
                          <div className="col-6">
                            <div className="title position-relative pl-3">
                              <span className="bg-danger rounded" />
                              <h6>HTTP Error</h6>
                              <p className="mb-0">500</p>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="title position-relative pl-3">
                              <span className="bg-warning rounded" />
                              <h6>HTTP Error</h6>
                              <p className="mb-0">403</p>
                            </div>
                          </div>
                          <div className="col-6 mt-3">
                            <div className="title position-relative pl-3">
                              <span className="bg-success rounded" />
                              <h6>HTTP Error</h6>
                              <p className="mb-0">404</p>
                            </div>
                          </div>
                          <div className="col-6 mt-3">
                            <div className="title position-relative pl-3">
                              <span className="bg-primary rounded" />
                              <h6>HTTP Error</h6>
                              <p className="mb-0">400</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Daily Traffic Overview</h4>
                        </div>
                        <div className="mt-1">
                          <div className="d-flex align-items-center justify-content-between">
                            <a href="#" className="d-flex align-items-center mr-4">
                              <span className="bg-primary p-1 rounded mr-2" />
                              <p className="text-primary mb-0">Upload</p>
                            </a>
                            <a href="#" className="d-flex align-items-center">
                              <span className="bg-danger p-1 rounded mr-2" />
                              <p className="text-danger mb-0">Download</p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="iq-card-body">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="col-md-3">
                            <div className="chart-data-block">
                              <div className="mb-4">
                                <div className="d-flex">
                                  <h2 className="line-height-4 mb-1">8.27</h2>
                                  <p>/20GB</p>
                                </div>
                                <span className="text-danger font-size-12 font-weight-500">Daily Traffic</span>
                              </div>
                              <div className="mb-0">
                                <div className="d-flex">
                                  <h2 className="line-height-4 mb-1">2.56</h2>
                                  <p>/5GB</p>
                                </div>
                                <span className="text-danger font-size-12 font-weight-500">Hourly Traffic</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-9 pr-0">
                            <div id="trafic-chart" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="iq-card iq-card-block iq-card-stretch iq-card-height">
                      <div className="iq-card-header d-flex justify-content-between border-none">
                        <div className="iq-header-title">
                          <h5 className="card-title">Load time by country</h5>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <a href="#" className="d-flex align-items-center mr-2">
                            <span className="bg-primary p-1 rounded mr-2" />
                            <p className="text-primary mb-0">Selected server</p>
                          </a>
                          <a href="#" className="d-flex align-items-center">
                            <span className="bg-danger p-1 rounded mr-2" />
                            <p className="text-danger mb-0">Available server</p>
                          </a>
                        </div>
                      </div>
                      <div className="iq-card-body">
                        <div id="world-map" style={{ height: 250 }} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-lg-6">
                    <div className="iq-card">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Product Orders</h4>
                        </div>
                      </div>
                      <div className="iq-card-body">
                        <ul className="iq-timeline">
                          <li>
                            <div className="timeline-dots">
                              <i className="ri-pantone-line" />
                            </div>
                            <h6 className="float-left mb-1">Client Meeting</h6>
                            <small className="float-right mt-1">19 November 2019</small>
                            <div className="d-inline-block w-100">
                              <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-dots border-success">
                              <i className="ri-pantone-line" />
                            </div>
                            <h6 className="float-left mb-1">Client Meeting</h6>
                            <small className="float-right mt-1">19 November 2019</small>
                            <div className="d-inline-block w-100">
                              <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-dots border-danger">
                              <i className="ri-pantone-line" />
                            </div>
                            <h6 className="float-left mb-1">Client Meeting</h6>
                            <small className="float-right mt-1">19 November 2019</small>
                            <div className="d-inline-block w-100">
                              <p>
                                Bonbon macaroon jelly beans <a href="#">gummi bears</a>gummi bears jelly lollipop apple
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-dots border-primary">
                              <i className="ri-pantone-line" />
                            </div>
                            <h6 className="float-left mb-1">Client Meeting</h6>
                            <small className="float-right mt-1">19 November 2019</small>
                            <div className="d-inline-block w-100">
                              <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-dots border-info">
                              <i className="ri-pantone-line" />
                            </div>
                            <h6 className="float-left mb-1">Client Meeting</h6>
                            <small className="float-right mt-1">19 November 2019</small>
                            <div className="d-inline-block w-100">
                              <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-dots border-info">
                              <i className="ri-pantone-line" />
                            </div>
                            <h6 className="float-left mb-1">Client Meeting</h6>
                            <small className="float-right mt-1">19 November 2019</small>
                            <div className="d-inline-block w-100">
                              <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-dots border-warning">
                              <i className="ri-pantone-line" />
                            </div>
                            <h6 className="float-left mb-1">Client Meeting</h6>
                            <small className="float-right mt-1">19 November 2019</small>
                            <div className="d-inline-block w-100">
                              <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-dots border-info">
                              <i className="ri-pantone-line" />
                            </div>
                            <h6 className="float-left mb-1">Client Meeting</h6>
                            <small className="float-right mt-1">19 November 2019</small>
                            <div className="d-inline-block w-100">
                              <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="iq-card">
                      <div className="iq-card-header d-flex justify-content-between">
                        <div className="iq-header-title">
                          <h4 className="card-title">Product Orders</h4>
                        </div>
                      </div>
                      <div className="iq-card-body">
                        <ul className="iq-timeline">
                          <li>
                            <div className="timeline-dots">
                              <i className="ri-pantone-line" />
                            </div>
                            <h6 className="float-left mb-1">Client Meeting</h6>
                            <small className="float-right mt-1">19 November 2019</small>
                            <div className="d-inline-block w-100">
                              <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-dots border-success">
                              <i className="ri-pantone-line" />
                            </div>
                            <h6 className="float-left mb-1">Client Meeting</h6>
                            <small className="float-right mt-1">19 November 2019</small>
                            <div className="d-inline-block w-100">
                              <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-dots border-danger">
                              <i className="ri-pantone-line" />
                            </div>
                            <h6 className="float-left mb-1">Client Meeting</h6>
                            <small className="float-right mt-1">19 November 2019</small>
                            <div className="d-inline-block w-100">
                              <p>
                                Bonbon macaroon jelly beans <a href="#">gummi bears</a>gummi bears jelly lollipop apple
                              </p>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-dots border-primary">
                              <i className="ri-pantone-line" />
                            </div>
                            <h6 className="float-left mb-1">Client Meeting</h6>
                            <small className="float-right mt-1">19 November 2019</small>
                            <div className="d-inline-block w-100">
                              <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-dots border-info">
                              <i className="ri-pantone-line" />
                            </div>
                            <h6 className="float-left mb-1">Client Meeting</h6>
                            <small className="float-right mt-1">19 November 2019</small>
                            <div className="d-inline-block w-100">
                              <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-dots border-info">
                              <i className="ri-pantone-line" />
                            </div>
                            <h6 className="float-left mb-1">Client Meeting</h6>
                            <small className="float-right mt-1">19 November 2019</small>
                            <div className="d-inline-block w-100">
                              <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-dots border-warning">
                              <i className="ri-pantone-line" />
                            </div>
                            <h6 className="float-left mb-1">Client Meeting</h6>
                            <small className="float-right mt-1">19 November 2019</small>
                            <div className="d-inline-block w-100">
                              <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                            </div>
                          </li>
                          <li>
                            <div className="timeline-dots border-info">
                              <i className="ri-pantone-line" />
                            </div>
                            <h6 className="float-left mb-1">Client Meeting</h6>
                            <small className="float-right mt-1">19 November 2019</small>
                            <div className="d-inline-block w-100">
                              <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* Wrapper END */}
          {/* Footer */}
          <footer className="iq-footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <ul className="list-inline mb-0">
                    {/* <li className="list-inline-item">
                      <a href="privacy-policy.html">Privacy Policy</a>
                    </li>
                    <li className="list-inline-item">
                      <a href="terms-of-service.html">Terms of Use</a>
                    </li> */}
                  </ul>
                </div>
                <div className="col-lg-6 text-right">
                  Copyright 2022 <a href="http://nomadis.online">Clediss</a> All Rights Reserved.
                </div>
              </div>
            </div>
          </footer>
        </div>
      )}
    </>
  );
}

export default Dashboard;
