import React from "react";

function DomaineTable({ domaines }) {
  return (
    <div className="table-responsive">
      <table id="datatable" className="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Url</th>
            <th>Status</th>
            <th>nom de domaine</th>
            <th>Date d'expiration</th>
            <th>registrar</th>
            <th>registrar id</th>
            <th>Provider</th>
            <th>Domaine Id</th>
          </tr>
        </thead>
        <tbody>
          {domaines.map((el, idx) => (
            <tr key={idx}>
              <td>{el?.url}</td>
              <td>
                {el.etat == "active" ? (
                  <span className="badge iq-bg-success">{el.etat}</span>
                ) : (
                  <span className="badge iq-bg-danger">{el.etat}</span>
                )}
              </td>
              <td>{el?.domain_name}</td>
              <td>{el?.expiration_date}</td>
              <td>{el?.registrar}</td>
              <td>{el?.registrar_id}</td>
              <td>{el?.whois_server}</td>
              <td>{el?.domain__id}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th>Url</th>
            <th>Status</th>
            <th>nom de domaine</th>
            <th>Date d'expiration</th>
            <th>registrar</th>
            <th>registrar id</th>
            <th>Provider</th>
            <th>Domaine Id</th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

export default DomaineTable;
