/**
 *
 * @param {*} value
 * @returns
 */
export function getSession(value) {
  return localStorage.getItem(value);
}
/**
 * check if user is connected
 * @returns
 */
export const isLogin = () => {
  if (getSession("loginServer") == "true") {
    return true;
  }
  return false;
};
