import React from "react";

function IpsTables({ ips }) {
  return (
    <table
      id="user-list-table"
      className="table table-striped table-bordered mt-4"
      role="grid"
      aria-describedby="user-list-page-info"
    >
      <thead>
        <tr>
          <th>Profile</th>
          <th>Ip</th>
          <th>Hostname</th>
          <th>Organisation</th>
          <th>Postal</th>
          <th>Status</th>
          <th>Région</th>
          <th>TimeZone</th>
          {/* <th>Action</th> */}
        </tr>
      </thead>
      <tbody>
        {ips.map((el, idx) => (
          <tr key={idx}>
            <td className="text-center">
              <div className="avatar-55 text-center rounded iq-bg-danger">
                <span>{el.country}</span>
              </div>
            </td>
            <td>{el.ip}</td>
            <td>{el.hostname}</td>
            <td>{el.org}</td>
            <td>{el.postal}</td>
            <td>
              {el.status == "active" ? (
                <span className="badge iq-bg-success">{el.status}</span>
              ) : (
                <span className="badge iq-bg-danger">{el.status}</span>
              )}
            </td>
            <td>{el.region}</td>
            <td>{el.timezone}</td>
            {/* <td>
              <div className="d-flex align-items-center list-user-action">
                <a
                  className="iq-bg-primary"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-original-title="Add"
                  href="#"
                >
                  <i className="ri-user-add-line" />
                </a>
                <a
                  className="iq-bg-primary"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-original-title="Edit"
                  href="#"
                >
                  <i className="ri-pencil-line" />
                </a>
                <a
                  className="iq-bg-primary"
                  data-toggle="tooltip"
                  data-placement="top"
                  data-original-title="Delete"
                  href="#"
                >
                  <i className="ri-delete-bin-line" />
                </a>
              </div>
            </td> */}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default IpsTables;
