const _ = require("lodash");
export function getAllServers(data) {
  var newList = [];
  var object = _.chain(data)
    // Group the elements of Array based on `color` property
    .groupBy("server_name")
    // `key` is group's name (color), `value` is the array of objects
    .map((value, key) => ({ server: key, users: value }))
    .value();

  object.forEach((element) => {
    newList.push(element.server);
  });
  return newList;
}
