import React from "react";

function DiskTables({ disks }) {
  return (
    <div className="table-responsive">
      <table className="table mb-0 mt-2 table-borderless table-test tbl-server-info">
        <thead>
          <tr className="text-center">
            <th scope="col" className="text-left">
              Nom
            </th>
            <th scope="col">Monté</th>
            <th scope="col">Total Vide</th>
            <th scope="col">Total</th>
            <th scope="col">Total utilisé</th>
            <th scope="col">Utilisation</th>
            <th scope="col">Point de montage</th>
            {/* <th scope="col">Actions</th> */}
          </tr>
        </thead>
        <tbody>
          {disks.map((el, idx) => (
            <tr key={idx}>
              <td>
                <h6>{el.name}</h6>
                <div className="btn iq-bg-danger mt-2">{el.type}</div>
              </td>
              <td className="text-center">
                <div className="text-open font-size-24">
                  <i className="ri-arrow-up-fill" />
                </div>
              </td>
              <td className="text-center">
                <div className="api-chart-02 d-inline-block" />
                <span className="d-block mt-2 text-danger">{el.total_free}</span>
              </td>
              <td className="text-center">
                <div className="api-chart-02 d-inline-block" />
                <span className="d-block mt-2 text-success">{el.total_size}</span>
              </td>
              <td className="text-center">
                <div className="apitime-chart-02 d-inline-block" />
                <span className="d-block mt-2 text-primary">{el.used_size}</span>
              </td>
              <td className="text-center">
                <div className="text-black">{el.percent_used} %</div>
              </td>
              <td className="text-center">
                <div className="text-black">{el.mount_point}</div>
              </td>
              {/* <td className="text-center m-auto">
                <div className="d-flex align-items-center justify-content-center">
                  <a href="#" className="avatar-45 text-center ml-3 rounded-circle iq-bg-primary font-size-18">
                    <i className="ri-pencil-fill" />
                  </a>
                  <a href="#" className="avatar-45 text-center ml-3 rounded-circle iq-bg-danger font-size-18">
                    <i className="ri-bar-chart-fill" />
                  </a>
                  <a href="#" className="avatar-45 rounded-circle text-center ml-3 iq-bg-dark font-size-18">
                    <i className="ri-repeat-2-line" />
                  </a>
                  <a href="#" className="avatar-45 rounded-circle text-center ml-3 iq-bg-success font-size-18">
                    <i className="ri-pause-line" />
                  </a>
                </div>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-block text-center text-black font-weight-500">
        Voir plus
        <i className="ri-arrow-down-s-line" />
      </div>
    </div>
  );
}

export default DiskTables;
